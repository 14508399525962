// Generated by Framer (b0f2619)

import { addFonts, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["UuiSK49WS"];

const serializationHash = "framer-XcODy"

const variantClassNames = {UuiSK49WS: "framer-v-2px1uz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "UuiSK49WS", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-2px1uz", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"UuiSK49WS"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(0, 0, 0)", ...style}}><motion.div className={"framer-d59q4w"} layoutDependency={layoutDependency} layoutId={"Q9pE4jr7J"} style={{backgroundColor: "var(--token-b9044daf-0a3d-44a3-b269-b5eaeda29443, rgb(22, 28, 34))"}}/><motion.div className={"framer-1itu6u4"} layoutDependency={layoutDependency} layoutId={"TCz7Ytejn"} style={{backgroundColor: "var(--token-b9044daf-0a3d-44a3-b269-b5eaeda29443, rgb(22, 28, 34))"}}/><motion.div className={"framer-1ssorg0"} layoutDependency={layoutDependency} layoutId={"MjPSarYck"} style={{backgroundColor: "var(--token-d8725aa4-28a0-4315-9419-74c7aa453473, rgb(92, 111, 138))"}}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-XcODy[data-border=\"true\"]::after, .framer-XcODy [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XcODy.framer-2ychrg, .framer-XcODy .framer-2ychrg { display: block; }", ".framer-XcODy.framer-2px1uz { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 185px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 185px; }", ".framer-XcODy .framer-d59q4w { bottom: 0px; flex: none; left: calc(50.27027027027029% - 1px / 2); overflow: hidden; position: absolute; top: 0px; width: 1px; z-index: 1; }", ".framer-XcODy .framer-1itu6u4 { flex: none; height: 1px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: calc(50.27027027027029% - 1px / 2); z-index: 1; }", ".framer-XcODy .framer-1ssorg0 { flex: none; height: 2px; left: calc(50.27027027027029% - 2px / 2); overflow: hidden; position: absolute; top: calc(50.27027027027029% - 2px / 2); width: 2px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-XcODy.framer-2px1uz { gap: 0px; } .framer-XcODy.framer-2px1uz > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-XcODy.framer-2px1uz > :first-child { margin-top: 0px; } .framer-XcODy.framer-2px1uz > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 185
 * @framerIntrinsicWidth 185
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerORL8C3LHo: React.ComponentType<Props> = withCSS(Component, css, "framer-XcODy") as typeof Component;
export default FramerORL8C3LHo;

FramerORL8C3LHo.displayName = "Square";

FramerORL8C3LHo.defaultProps = {height: 185, width: 185};

addFonts(FramerORL8C3LHo, [])